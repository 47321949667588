














































































































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
  useFetch
} from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import VaimoDatePickerWrapper from 'atoms/helper/VaimoDatePickerWrapper.vue';
import VaimoTooltip from 'atoms/VaimoTooltip.vue';
import VaimoPhone from 'organisms/VaimoPhone.vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { min, required } from 'vee-validate/dist/rules';

import { useConfig, useStore, useUiNotification } from '~/composables';
import { useDatePicker } from '~/diptyqueTheme/composable/useDatePicker';
import { lsGet } from '~/diptyqueTheme/composable/useLocalStorage';
import { usePhoneMask } from '~/diptyqueTheme/composable/usePhoneMask';
import { useRules } from '~/diptyqueTheme/composable/useRules';
import { formatDate } from '~/diptyqueTheme/helpers/dateFormatter';
import { formatJapaneseDate } from '~/diptyqueTheme/helpers/formatJapaneseDate';
import {
  charsRegex,
  doubleQuotesRegex,
  emailRegex,
  hiraganaRegex,
  kanaRegex,
  katakanaRegex,
  nameRegex,
  phoneRegex
} from '~/diptyqueTheme/helpers/regexes';
import { useUser } from '~/modules/customer/composables/useUser';
import userGetters from '~/modules/customer/getters/userGetters';
import {
  customerPasswordRegExp,
  invalidPasswordMsg
} from '~/modules/customer/helpers/passwordValidation';

extend('email_ex', {
  message: 'Invalid email address',
  validate(value) {
    return {
      valid: emailRegex.test(value)
    };
  }
});
extend('name', {
  message: 'Use only alphabetic letters',
  validate(value) {
    return {
      valid: nameRegex.test(value)
    };
  }
});
extend('required', {
  ...required,
  message: 'This field is required'
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters'
});
extend('phone', {
  validate(value) {
    return {
      valid: phoneRegex.test(value.replace(/\D/g, ''))
    };
  }
});
extend('password', {
  message: invalidPasswordMsg,
  validate: (value: string) => customerPasswordRegExp.test(value)
});

extend('doubleQuotes', {
  message: '" is a forbidden character',
  validate(value) {
    return {
      valid: doubleQuotesRegex.test(value)
    };
  }
});
extend('chars', {
  message: 'Only these characters',
  validate(value) {
    return {
      valid: charsRegex.test(value)
    };
  }
});

extend('validate-katakana', {
  message: 'Please enter in the full-width katakana.',
  validate(value) {
    return {
      valid: katakanaRegex.test(value)
    };
  }
});

extend('validate-hiragana', {
  message: 'Please use Hiragana only in this field.',
  validate(value) {
    return {
      valid: hiraganaRegex.test(value)
    };
  }
});

extend('validate-kana', {
  message: 'Please use full width kana only in this field.',
  validate(value) {
    return {
      valid: kanaRegex.test(value)
    };
  }
});

export default defineComponent({
  name: 'MyProfile',
  components: {
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoPasswordInput: () => import('organisms/VaimoPasswordInput.vue'),
    SfInput,
    ValidationProvider,
    ValidationObserver,
    VaimoPhone,
    VaimoDatePickerWrapper,
    VaimoTooltip
  },
  setup(props, { refs }) {
    const { isJpStore, isUsCaStore, getNameKanaRules } = useStore();
    const { config } = useConfig();
    const { app, i18n } = useContext();
    const { state } = app.context.$vsf.$magento.config;
    const customerToken = state.getCustomerToken();
    const confirm_password_type = ref('password');
    const new_password_type = ref('password');
    const current_password_type = ref('password');
    const { getDatePickerLang, getDateFormat } = useDatePicker();
    const { getRules } = useRules();
    const isPhoneValid = ref(true);

    const {
      mask: phoneMask,
      maxLength: phoneLength,
      placeholder: phonePlaceholder
    } = usePhoneMask();
    const {
      updateCustomerEmail,
      updateUser,
      changePassword,
      changePasswordSocials,
      loading,
      error,
      user,
      load: loadUser
    } = useUser();
    const updateError = ref('');
    const { send: sendNotification } = useUiNotification();
    const nameKanaRules = getNameKanaRules();

    const isLoggedInWithSocials = computed(() => {
      let strategy = lsGet('strategy');
      return strategy === 'facebook' || strategy === 'google';
    });

    const form = ref({
      email: '',
      firstname: '',
      lastname: '',
      mobile: '',
      date_of_birth: '',
      firstnamekana: null,
      lastnamekana: null,
      password: ''
    });

    const changePasswordData = ref({
      currentPass: '',
      newPass: '',
      confirmedNewPass: ''
    });
    const isEmailChanged = computed(
      () => userGetters.getEmailAddress(user.value) !== form.value.email
    );

    const matchPasswords = computed(() => {
      if (
        changePasswordData.value.newPass !==
        changePasswordData.value.confirmedNewPass
      ) {
        updateError.value = i18n.t(
          'Passwords do not match, please try again.'
        ) as string;
        return true;
      }
      updateError.value = '';
      return false;
    });

    const onDateInput = (value) => {
      form.value.date_of_birth = isJpStore.value
        ? formatJapaneseDate(value)
        : formatDate(value);
    };

    const checkPasswordFields = computed(() => {
      return !!(
        (isLoggedInWithSocials.value || changePasswordData.value.currentPass) &&
        changePasswordData.value.newPass &&
        changePasswordData.value.confirmedNewPass
      );
    });

    const updateCustomer = async () => {
      if (!isPhoneValid.value) {
        return;
      }

      const userData = { ...form.value };
      if (isEmailChanged.value) {
        await updateCustomerEmail({
          email: userData.email,
          password: userData.password
        });

        delete userData.email;
        delete userData.password;
      }

      const dateOfBirth =
        form.value.date_of_birth?.split('/').reverse().join('-') || '';
      const JpDateOfBirth = dateOfBirth?.split('-').reverse().join('-') || '';
      await updateUser({
        user: {
          ...userData,
          date_of_birth: isJpStore.value ? JpDateOfBirth : dateOfBirth
        }
      });
      await loadUser({
        customQuery: {
          customer: 'customerWithOptions'
        }
      });
      form.value.date_of_birth =
        form.value.date_of_birth?.split('-').reverse().join('/') || '';
      showNotification();
    };

    const showNotification = () => {
      sendNotification({
        id: Symbol('account_updated'),
        message: i18n.t('You saved the account information') as string,
        type: 'success',
        persist: false,
        title: i18n.t('Account information update') as string
      });
    };

    const changeCustomerPassword = async (isInvalidForm) => {
      if (
        isInvalidForm ||
        !checkPasswordFields.value ||
        loading.value ||
        matchPasswords.value
      ) {
        return false;
      }

      const { currentPass, newPass } = changePasswordData.value;

      if (isLoggedInWithSocials.value) {
        await changePasswordSocials({
          id: customerToken,
          email: user.value?.email,
          new: newPass
        });
      } else {
        await changePassword({
          current: currentPass,
          new: newPass
        });
      }

      if (error.value.changePassword || error.value.changePasswordSocials) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updateError.value =
          error.value.changePassword || error.value.changePasswordSocials;
        setTimeout(() => {
          updateError.value = '';
        }, 5000);
      } else {
        showNotification();
        changePasswordData.value.currentPass = '';
        changePasswordData.value.newPass = '';
        changePasswordData.value.confirmedNewPass = '';
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        refs.passwordForm.reset();
      }
    };

    const fillForm = () => {
      form.value.firstname = user.value?.firstname;
      form.value.lastname = user.value?.lastname;
      form.value.firstnamekana = user.value?.firstnamekana;
      form.value.lastnamekana = user.value?.lastnamekana;
      form.value.email = user.value?.email;
      form.value.date_of_birth = isJpStore.value
        ? user.value?.date_of_birth
          ? user.value.date_of_birth.split('-').join('/')
          : ''
        : user.value?.date_of_birth
        ? user.value.date_of_birth.split('-').reverse().join('/')
        : '';
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      form.value.mobile =
        user.value?.mobile === '0000000000' ? '' : user.value?.mobile;
    };

    useFetch(async () => {
      await loadUser({
        customQuery: {
          customer: 'customerWithOptions'
        }
      });
      fillForm();
    });

    const phoneValidHandler = (value) => {
      isPhoneValid.value = value;
    };

    onMounted(() => {
      // adding aria-label on buttons in Login credentials for accessibility purpose
      const loginCredentials = refs.loginCredentials as any;
      const buttons = loginCredentials
        ? loginCredentials.querySelectorAll(
            'label+.sf-input__button.sf-button--pure'
          )
        : [];
      buttons.forEach((button) => {
        button.setAttribute('aria-label', i18n.t('Switch password visibility'));
      });
    });

    return {
      config,
      isJpStore,
      isUsCaStore,
      form,
      user,
      onDateInput,
      changePasswordData,
      checkPasswordFields,
      updateError,
      updateCustomer,
      loading,
      confirm_password_type,
      new_password_type,
      current_password_type,
      matchPasswords,
      changeCustomerPassword,
      phoneMask,
      phoneLength,
      phonePlaceholder,
      isLoggedInWithSocials,
      getDatePickerLang,
      getDateFormat,
      getRules,
      nameKanaRules,
      isPhoneValid,
      phoneValidHandler,
      isEmailChanged
    };
  }
});
